<template>
  <div class="container">
    <div class="header">
      <div class="back" @click="goBack">
        <i class="el-icon-arrow-left"></i>
        返回
      </div>
      <div class="title">
        <span>客户详情 - {{ 
          contactInfo.contact_lastName?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') 
        }} {{ 
          contactInfo.contact_givenName?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') 
        }} {{ 
          contactInfo.contact_enName ? `(${contactInfo.contact_enName?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')})` : '' 
        }}</span>
      </div>
      <div style="margin-left: auto">
        <el-button type="primary" size="small" style="margin-right: 10px" @click="composeNewBDEmail">新邮件撰写</el-button>
        <el-button type="primary" size="small" @click="showAnalysisDialog">客户分析</el-button>
        <el-button type="primary" size="small" style="margin-left: 10px" @click="printAllData">打印全部</el-button>
      </div>
    </div>

    <div class="content" v-loading="loading">
      <el-tabs v-model="activeTab" type="card">
        <el-tab-pane label="基本信息" name="basic">
          <div class="info-section">
            <div class="section-title">联系人信息</div>
            <el-form label-width="120px" class="info-form">
              <div class="form-row">
                <el-form-item label="姓名">
                  <span>{{ contactInfo.contact_lastName }} {{ contactInfo.contact_givenName }}</span>
                </el-form-item>
                <el-form-item label="英文名">
                  <span>{{ contactInfo.contact_enName }}</span>
                </el-form-item>
                <el-form-item label="邮箱">
                  <span>{{ contactInfo.contact_email }}</span>
                </el-form-item>
                <el-form-item label="职务">
                  <span>{{ contactInfo.contact_position }} ({{ contactInfo.contact_position_type }})</span>
                </el-form-item>
                <el-form-item label="学科">
                  <span>{{ contactInfo.contact_discipline    }}</span>
                </el-form-item>
                <el-form-item label="补充信息">
                  <span>{{ contactInfo.contact_supplement }}</span>
                </el-form-item>
              </div>
            </el-form>
          </div>

          <div class="info-section">
            <div class="section-title">单位信息</div>
            <el-form label-width="120px" class="info-form">
              <div class="form-row">
                <el-form-item label="单位名称">
                  <div>{{ contactInfo.account_info.account_name }}</div>
                  <div>{{ contactInfo.account_info.account_nameEN }}</div>
                </el-form-item>
                <el-form-item label="国家/地区">
                  <span>{{ contactInfo.account_info.account_country }}</span>
                </el-form-item>
                <el-form-item label="类别">
                  <span>{{ contactInfo.account_info.account_category }}</span>
                </el-form-item>
                <el-form-item label="类型">
                  <span>{{ contactInfo.account_info.account_type }}</span>
                </el-form-item>
                <el-form-item label="网站">
                  <span>{{ contactInfo.account_info.account_website }}</span>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </el-tab-pane>

        
        <el-tab-pane label="AI分析" name="analysis">
          <div class="analysis-section">
            <el-card class="analysis-card">
              <div slot="header">
                <span>客户画像分析</span>
              </div>
              <div class="analysis-content">
                <!-- AI分析内容将在这里展示 -->
                <p>正在生成客户画像...</p>
              </div>
            </el-card>
          </div>
        </el-tab-pane>

        <!-- Add new tab pane for marketing emails -->
        <el-tab-pane label="营销邮件" name="marketing">
          <div class="marketing-section">
            <el-table
              v-loading="loadingEmails"
              :data="marketingEmails"
              style="width: 100%">
              <el-table-column
                prop="subject"
                label="邮件主题"
                min-width="200">
                <template slot-scope="scope">
                  <div>{{ scope.row.subject }}</div>
                  <div>{{ scope.row.sender_email }} ({{ scope.row.sender_name }})</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="sent_time"
                label="发送时间"
                width="180">
                <template slot-scope="scope">
                  {{ new Date(scope.row.send_time*1000).toLocaleString() }}
                </template>
              </el-table-column>
              <el-table-column
                prop="open_status"
                label="打开状态"
                width="200">
                <template slot-scope="scope">
                  <div>{{ scope.row.open_status ? `已打开 (${scope.row.open_count}次)` : '未打开' }}</div>
                  <div v-if="scope.row.open_status && scope.row.open_time_records">
                    <div v-for="(time, index) in scope.row.open_time_records" :key="index" style="font-size: 12px; color: #666;">
                      {{ new Date(time*1000).toLocaleString() }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="showEmailContent(scope.row)"
                  >
                    查看详情
                  </el-button>
                </template>
              </el-table-column>
              
            </el-table>
          </div>
        </el-tab-pane>

        <!-- Add new tab pane for company emails -->
        <el-tab-pane label="公司邮件" name="company">
          <div class="company-section">
            <el-table
              v-loading="loadingEmails"
              :data="companyEmails"
              style="width: 100%">
              <el-table-column
                prop="subject"
                label="邮件主题"
                min-width="200">
                <template slot-scope="scope">
                  <div>{{ scope.row.subject }}</div>
                  <div>{{ scope.row.from }} ({{ scope.row.fromName }})</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="sent_time"
                label="发送时间"
                width="180">
                <template slot-scope="scope">
                  {{ scope.row.create_time ? new Date(scope.row.create_time*1000).toLocaleString() : new Date(scope.row.date).toISOString().slice(0, 19).replace('T', ' ') }}
                </template>
              </el-table-column>
              <el-table-column
                prop="open_status"
                label="打开状态"
                width="200">
                <template slot-scope="scope">
                  <div>{{ scope.row.open_status ? `已打开 (${scope.row.open_count}次)` : '未打开' }}</div>
                  <div v-if="scope.row.open_status && scope.row.open_time_records">
                    <div v-for="(time, index) in scope.row.open_time_records" :key="index" style="font-size: 12px; color: #666;">
                      {{ new Date(time*1000).toLocaleString() }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="showEmailContent(scope.row)"
                  >
                    查看详情
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>

        <!-- Add new tab pane for account applications -->
        <el-tab-pane label="单位项目" name="account">
          <div class="account-section">
            <el-table
              v-loading="loadingApplications" 
              :data="accountApplications"
              style="width: 100%">
              <el-table-column
                prop="project_code"
                label="项目编号"
                min-width="120">
                <template slot-scope="scope">
                  {{ scope.row.project_code }}
                </template>
              </el-table-column>
              <el-table-column
                prop="application_contact_names"
                label="负责老师"
                min-width="120">
                <template slot-scope="scope">
                  {{ Array.isArray(scope?.row?.application_contact_names) ? scope.row.application_contact_names.join(', ') : '' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="application_status"
                label="报名状态"
                min-width="120">
                <template slot-scope="scope">
                  {{ scope.row.application_status }}
                </template>
              </el-table-column>
              <el-table-column
                prop="current_app_count"
                label="报名表数量"
                min-width="120">
                <template slot-scope="scope">
                  {{ scope.row.current_app_count }}
                </template>
              </el-table-column>
              <el-table-column
                prop="create_time"
                label="创建时间"
                min-width="180">
                <template slot-scope="scope">
                  {{ new Date(scope.row.create_time*1000).toLocaleString() }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>

        <el-tab-pane label="客户负责项目" name="contactAccount">
          <div class="account-section">
            <el-table
              v-loading="loadingApplications"
              :data="contactAccountApplications" 
              style="width: 100%">
              <el-table-column
                prop="project_code"
                label="项目编号"
                min-width="120">
                <template slot-scope="scope">
                  {{ scope.row.project_code }}
                </template>
              </el-table-column>
              <el-table-column
                prop="application_contact_names"
                label="负责老师"
                min-width="120">
                <template slot-scope="scope">
                  {{ Array.isArray(scope?.row?.application_contact_names) ? scope.row.application_contact_names.join(', ') : '' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="application_status"
                label="报名状态"
                min-width="120">
                <template slot-scope="scope">
                  {{ scope.row.application_status }}
                </template>
              </el-table-column>
              <el-table-column
                prop="current_app_count"
                label="报名表数量"
                min-width="120">
                <template slot-scope="scope">
                  {{ scope.row.current_app_count }}
                </template>
              </el-table-column>
              <el-table-column
                prop="create_time"
                label="创建时间"
                min-width="180">
                <template slot-scope="scope">
                  {{ new Date(scope.row.create_time*1000).toLocaleString() }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>

        <el-tab-pane label="AI BD邮件" name="aiBDEmail">
          <ai-bd-mail-list
            :contact-id="contactId"
            filter-type="contactID"
            @download="handleAttachmentDownload"
          />
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- Add this dialog component before closing the template tag -->
    <el-dialog
      title="AI 客户分析"
      :visible.sync="analysisDialogVisible"
      width="70%"
      :before-close="handleCloseDialog">
      <div class="chat-container">
        <div class="chat-messages" ref="chatMessages">
          <div v-for="(message, index) in chatMessages" :key="index" 
               :class="['message', message.role]">
            <div class="message-content">{{ message.content }}</div>
          </div>
        </div>
        <div class="chat-input">
          <el-input
            v-model="userInput"
            placeholder="输入您的问题..."
            :disabled="loading"
            @keyup.enter.native="sendMessage">
            <el-button slot="append" 
                      @click="sendMessage" 
                      :loading="loading">发送</el-button>
          </el-input>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="邮件详情"
      :visible.sync="emailDialogVisible"
      width="70%"
      :before-close="handleEmailDialogClose"
    >
      <email-details
        :email-data="selectedEmail"
        @download="handleAttachmentDownload"
      />
    </el-dialog>

    <!-- Add new dialog for email content -->
    <el-dialog
      title="邮件内容"
      :visible.sync="emailContentDialogVisible"
      width="70%">
      <div v-if="selectedEmailContent">
        <div class="email-header">
          <p><strong>主题：</strong>{{ selectedEmailContent.subject }}</p>
          <p><strong>发件人：</strong>{{ selectedEmailContent.sender }}</p>
          <p><strong>时间：</strong>{{ selectedEmailContent.time }}</p>
        </div>
        <div class="email-body" v-html="selectedEmailContent.content"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { postAccountRequest } from '@/api/assistant'
import EmailDetails from '@/components/EmailDetails.vue'
import AiBdMailList from '@/components/AiBdMailList.vue'

export default {
  name: 'CustomerDetails',
  components: {
    EmailDetails,
    AiBdMailList
  },
  data() {
    return {
      activeTab: 'basic',
      loading: true,
      loadingEmails: false,
      contactInfo: {},
      activities: [],
      contactId: this.$route.query.id,
      marketingEmails: [],
      companyEmails: [],
      accountApplications: [],
      contactAccountApplications: [],
      analysisDialogVisible: false,
      chatMessages: [],
      userInput: '',
      aiMarketingBDMails: [],
      emailDialogVisible: false,
      selectedEmail: {},
      emailClientContext: {
        interactions: []
      },
      emailContentDialogVisible: false,
      selectedEmailContent: null,
    }
  },
  created() {
    this.getCustomerDetails()
    this.getContactMarketingEmails()
    this.getContactCompanyEmails()
    this.getAccountApplications()
    this.getContactAccountApplications()
    // this.getAIMarketingBDMails()
  },
  watch: {
    activeTab: {
      handler(newVal) {
        // if (newVal === 'marketing') {
        //   this.getContactMarketingEmails()
        // } else if (newVal === 'company') {
        //   this.getContactCompanyEmails()
        // } else if (newVal === 'account') {
        //   this.getAccountApplications()
        // } else if (newVal === 'contactAccount') {
        //   this.getContactAccountApplications()
        // }
      },
      immediate: true
    }
  },
  methods: {
    goBack() {
      this.$router.push('/aicustomer')
    },
    async getCustomerDetails() {
      try {
        
        const response = await postAccountRequest('getContactDetails', {
          contact_id: this.contactId
        })
        this.contactInfo = response.data
        this.loading = false
      } catch (error) {
        this.$message.error('获取客户详情失败')
        console.error('Error fetching customer details:', error)
        this.loading = false
      }
    },
    async getContactMarketingEmails() {
      this.loadingEmails = true
      try {
        const response = await postAccountRequest('getContactMarketingMails', {
          contact_id: this.contactId
        })
        this.marketingEmails = response.data.data
      } catch (error) {
        this.$message.error('获取营销邮件记录失败')
        console.error('Error fetching marketing emails:', error)
      } finally {
        this.loadingEmails = false
      }
    },

    async getContactCompanyEmails() {
      this.loadingEmails = true
      try {
        const response = await postAccountRequest('getContactCompanyEmails', {
          contact_id: this.contactId
        })
        this.companyEmails = response.data.data
      } catch (error) {
        this.$message.error('获取公司邮件记录失败')
        console.error('Error fetching company emails:', error)
      } finally {
        this.loadingEmails = false
      }
    },
    async getAccountApplications() {
      this.loadingApplications = true
      try {
        const response = await postAccountRequest('findSingleAccountApplicationsByContactId', {
          contact_id: this.contactId
        })
        this.accountApplications = response.data.data
      } catch (error) {
        this.$message.error('获取账户申请记录失败')
        console.error('Error fetching account applications:', error)
      } finally {
        this.loadingApplications = false
      }
    },
    async getContactAccountApplications() {
      this.loadingApplications = true
      try {
        const response = await postAccountRequest('findContactAccountApplications', {
          contact_id: this.contactId
        })
        this.contactAccountApplications = response.data.data
      } catch (error) {
        this.$message.error('获取联系人申请记录失败')
        console.error('Error fetching contact applications:', error)
      } finally {
        this.loadingApplications = false
      }
    },
    async getAIMarketingBDMails() {
      this.loadingEmails = true
      try {
        const response = await postAccountRequest('getAIMarketingBDMailsByContactId', {
          contact_id: this.contactId
        })
        this.aiMarketingBDMails = response.data.data
      } catch (error) {
        this.$message.error('获取AI营销邮件记录失败')
        console.error('Error fetching AI marketing emails:', error)
      } finally {
        this.loadingEmails = false
      }
    },
    composeNewBDEmail() {
        // Add loading state
        this.$loading({
            lock: true,
            text: '正在生成邮件...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })

        const context_string = this.getClientContextString()

        // Call API to write new BD email
        postAccountRequest('writeNewBDEmail', {
            contact_id: this.contactId,
            contact_info_string: context_string
        }).then(response => {
            // Close loading
            this.$loading().close()
            
            if (response.data.code === 0) {
                const mailInfo = response.data.data
                // Open email compose dialog with generated content
                this.$confirm(`
                    <div style="margin-bottom: 15px">
                        <div style="font-weight: bold">邮件主题:</div> 
                        <div>${mailInfo.mail_title}</div>
                    </div>
                    <div>
                        <div style="font-weight: bold">邮件内容:</div>
                        <div>${mailInfo.mail_content}</div>
                    </div>
                `, '生成的邮件内容', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info',
                    width: '800px'
                }).then(() => {
                    // Handle confirmation - could open email client or copy to clipboard
                    this.$message.success('邮件内容已生成')
                    this.$refs.aiBdMailList.fetchMailList()
                }).catch(() => {
                    this.$refs.aiBdMailList.fetchMailList()
                    this.$message.info('已取消')
                })
            } else {
                this.$message.error('生成邮件失败：' + response.data.msg)
            }
        }).catch(error => {
            // Close loading on error
            this.$loading().close()
            console.error('Error generating email:', error)
            this.$message.error('生成邮件失败')
        })
    },
    getClientContextString(){
        // Sort all emails by date/time
        const sortedMarketingEmails = [...(this.marketingEmails || [])].sort((a, b) => 
            (b.send_time || 0) - (a.send_time || 0)
        )

        const sortedCompanyEmails = [...(this.companyEmails || [])].sort((a, b) => {
            const timeA = a.create_time ? a.create_time : new Date(a.date).getTime() / 1000
            const timeB = b.create_time ? b.create_time : new Date(b.date).getTime() / 1000
            return timeB - timeA
        })

        const context_string = `
            客户详情报告

            基本信息
            --------
            联系人信息：
            姓名: ${this.contactInfo.contact_lastName} ${this.contactInfo.contact_givenName}
            英文名: ${this.contactInfo.contact_enName}
            邮箱: ${this.contactInfo.contact_email}
            职务: ${this.contactInfo.contact_position} (${this.contactInfo.contact_position_type})
            学科: ${this.contactInfo.contact_discipline}
            补充信息: ${this.contactInfo.contact_supplement}

            单位信息：
            单位名称: ${this.contactInfo.account_info?.account_name}
            英文名称: ${this.contactInfo.account_info?.account_nameEN}
            国家/地区: ${this.contactInfo.account_info?.account_country}
            类型: ${this.contactInfo.account_info?.account_type}
            类别: ${this.contactInfo.account_info?.account_category}
            网站: ${this.contactInfo.account_info?.account_website}

            营销邮件记录：
            ${sortedMarketingEmails.length ? sortedMarketingEmails.slice(0, 20).map((email, index) => `
            [邮件 ${index + 1}]
            主题: ${email.subject}
            发件人: ${email.sender_email} (${email.sender_name})
            发送时间: ${new Date(email.send_time*1000).toLocaleString()}
            打开状态: ${email.open_status ? `已打开 (${email.open_count}次)` : '未打开'}`
            ).join('\n') : '暂无'}

            公司邮件记录：
            ${sortedCompanyEmails.length ? sortedCompanyEmails.slice(0, 20).map((email, index) => `
            [邮件 ${index + 1}]
            主题: ${email.subject}
            发件人: ${email.from} (${email.fromName})
            发送时间: ${email.create_time ? new Date(email.create_time*1000).toLocaleString() : new Date(email.date).toISOString().slice(0, 19).replace('T', ' ')}
            打开状态: ${email.open_status ? `已打开 (${email.open_count}次)` : '未打开'}`
            ).join('\n') : '暂无'}

            单位项目（请注意单位的项目并不一定是由沟通的客户联系人所负责的）：
            ${(this.accountApplications || [])
              .filter(app => {
                const projectYear = app.project_code.match(/\d{2,4}/)?.[0];
                if (!projectYear) return false;
                
                const currentYear = new Date().getFullYear();
                const year = projectYear.length === 2 ? 
                  2000 + parseInt(projectYear) : 
                  parseInt(projectYear);
                
                return currentYear - year <= 3;
              })
              .map(app => `
            项目编号: ${app.project_code}
            负责老师: ${Array.isArray(app.application_contact_names) ? app.application_contact_names.join(', ') : ''}
            报名状态: ${app.application_status}
            报名表数量: ${app.current_app_count}
            创建时间: ${new Date(app.create_time*1000).toLocaleString()}
            `).join('\n') || '暂无'}

            客户负责项目：
            ${(this.contactAccountApplications || [])
              .filter(app => {
                const projectYear = app.project_code.match(/\d{2,4}/)?.[0];
                if (!projectYear) return false;
                
                const currentYear = new Date().getFullYear();
                const year = projectYear.length === 2 ? 
                  2000 + parseInt(projectYear) : 
                  parseInt(projectYear);
                
                return currentYear - year <= 3;
              })
              .map(app => `
            项目编号: ${app.project_code}
            负责老师: ${Array.isArray(app.application_contact_names) ? app.application_contact_names.join(', ') : ''}
            报名状态: ${app.application_status}
            报名表数量: ${app.current_app_count}
            创建时间: ${new Date(app.create_time*1000).toLocaleString()}
            `).join('\n') || '暂无'}
        `
        return context_string
    },
    printAllData() {
      // Create a formatted string of all data
      const printContent = this.getClientContextString()

      // Create a new window for printing
      const printWindow = window.open('', '_blank')
      printWindow.document.write(`
        <html>
          <head>
            <title>客户详情报告</title>
            <style>
              body { font-family: Arial, sans-serif; line-height: 1.6; padding: 20px; }
              h1 { color: #333; }
              pre { white-space: pre-wrap; }
            </style>
          </head>
          <body>
            <pre>${printContent}</pre>
          </body>
        </html>
      `)
      printWindow.document.close()
      printWindow.print()
    },
    showAnalysisDialog() {
      this.analysisDialogVisible = true
      // Initialize chat with a welcome message
      this.chatMessages = [{
        role: 'assistant',
        content: '您好！我是AI助手，可以为您分析客户信息，请问有什么可以帮您？'
      }]
    },

    handleCloseDialog(done) {
      this.$confirm('确认关闭对话？')
        .then(_ => {
          this.chatMessages = []
          this.userInput = ''
          done()
        })
        .catch(_ => {})
    },

    async sendMessage() {
      if (!this.userInput.trim()) return

      const userMessage = this.userInput.trim()
      this.chatMessages.push({
        role: 'user',
        content: userMessage
      })
      this.userInput = ''
      this.loading = true

      try {
        // TODO: Replace with your actual API call
        const response = await postAccountRequest('aiAnalysis', {
          message: userMessage,
          contact_info: this.contactInfo
        })
        
        this.chatMessages.push({
          role: 'assistant',
          content: response.data.message || '抱歉，我现在无法回答这个问题。'
        })
      } catch (error) {
        this.$message.error('获取AI回复失败')
        this.chatMessages.push({
          role: 'assistant',
          content: '抱歉，系统出现错误，请稍后再试。'
        })
      } finally {
        this.loading = false
        this.$nextTick(() => {
          const chatMessages = this.$refs.chatMessages
          chatMessages.scrollTop = chatMessages.scrollHeight
        })
      }
    },
    async showEmailDetails(email) {
      this.selectedEmail = email

      this.emailDialogVisible = true
    },
    handleEmailDialogClose(done) {
      this.selectedEmail = {}
      done()
    },
    handleAttachmentDownload(attachment) {
      // Implement download logic
      console.log('Downloading attachment:', attachment)
    },
    // async handleDeleteEmail(email) {
    //   try {
    //     await this.$confirm('确认删除该邮件?', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     })
        
    //     const response = await postAccountRequest('deleteAIMarketingBDMail', {
    //       id: email._id.$id
    //     })
        
    //     if (response.data.code === 0) {
    //       this.$message.success('删除成功')
    //       // Refresh the email list
    //       this.getAIMarketingBDMails()
    //     } else {
    //       this.$message.error(response.data.message || '删除失败')
    //     }
    //   } catch (error) {
    //     if (error !== 'cancel') {
    //       console.error('Error deleting email:', error)
    //       this.$message.error('删除失败')
    //     }
    //   }
    // },
    // async handleShelveEmail(email) {
    //   try {
    //     await this.$confirm('确认搁置该邮件?', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     })
        
    //     const response = await postAccountRequest('changeAIMarketingBDMailStatus', {
    //       id: email._id.$id,
    //       status: 'CANCELLED'
    //     })
        
    //     if (response.data.code === 0) {
    //       this.$message.success('状态更新成功')
    //       // Refresh the email list
    //       this.getAIMarketingBDMails()
    //     } else {
    //       this.$message.error(response.data.message || '状态更新失败')
    //     }
    //   } catch (error) {
    //     if (error !== 'cancel') {
    //       console.error('Error updating email status:', error)
    //       this.$message.error('状态更新失败')
    //     }
    //   }
    // },
    showEmailContent(email) {
      // Format data differently based on email type
      if (this.activeTab === 'marketing') {
        this.selectedEmailContent = {
          subject: email.subject,
          sender: `${email.sender_email} (${email.sender_name})`,
          time: new Date(email.send_time * 1000).toLocaleString(),
          content: email.content || email.mail_content
        }
      } else {
        this.selectedEmailContent = {
          subject: email.subject,
          sender: `${email.from} (${email.fromName})`,
          time: email.create_time 
            ? new Date(email.create_time * 1000).toLocaleString()
            : new Date(email.date).toLocaleString(),
          content: email.content || email.mail_content
        }
      }
      
      this.emailContentDialogVisible = true
    },
    async sendEmail(email) {
      try {
        // Show confirmation dialog
        await this.$confirm(
          `确认发送邮件？\n\n收件人: ${email.contact_email}\n主题: ${email.mail_title}`, 
          '发送确认', 
          {
            confirmButtonText: '确定发送',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )

        // Show loading
        const loading = this.$loading({
          lock: true,
          text: '正在发送邮件...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        // Call API to send email
        const response = await postAccountRequest('sendAIMarketingBDMail', {
          mail_id: email._id.$id
        })

        loading.close()

        if (response.data.code === 0) {
          this.$message.success('邮件发送成功')
          // Refresh the email list
          this.getAIMarketingBDMails()
        } else {
          throw new Error(response.data.msg || '发送失败')
        }
      } catch (error) {
        if (error !== 'cancel') { // Ignore if user cancelled
          console.error('Error sending email:', error)
          this.$message.error(error.message || '邮件发送失败')
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
  height: 100vh;
  background: #f0f2f5;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .back {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #409EFF;
      margin-right: 20px;

      i {
        margin-right: 5px;
      }
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333;
    }
  }

  .content {
    background: #fff;
    padding: 20px;
    border-radius: 4px;

    .info-section {
      padding: 20px;

      .section-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
        color: #333;
      }

      .info-form {
        max-width: 100%;

        .form-row {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          .el-form-item {
            margin-bottom: 18px;
            flex: 1;
            min-width: 250px;
          }
        }
      }
    }

    .interaction-list {
      padding: 20px;
    }

    .analysis-section {
      padding: 20px;

      .analysis-card {
        margin-bottom: 20px;
      }

      .analysis-content {
        min-height: 200px;
      }
    }

    .marketing-section {
      padding: 20px;
    }

    .company-section {
      padding: 20px;
    }
  }
}

.chat-container {
  height: 60vh;
  display: flex;
  flex-direction: column;

  .chat-messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    background: #f5f7fa;
    margin-bottom: 20px;
    border-radius: 4px;

    .message {
      margin-bottom: 15px;
      
      &.user {
        text-align: right;
        
        .message-content {
          background: #409EFF;
          color: white;
          border-radius: 10px 10px 0 10px;
        }
      }
      
      &.assistant {
        text-align: left;
        
        .message-content {
          background: white;
          border: 1px solid #DCDFE6;
          border-radius: 10px 10px 10px 0;
        }
      }

      .message-content {
        display: inline-block;
        padding: 10px 15px;
        max-width: 70%;
        word-wrap: break-word;
      }
    }
  }

  .chat-input {
    padding: 10px 0;
  }
}

.email-header {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #EBEEF5;
  
  p {
    margin: 8px 0;
  }
}

.email-body {
  white-space: pre-wrap;
  line-height: 1.6;
}
</style> 